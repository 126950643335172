<template>
  <div class="row px-3 align-center py-2">
    <div class="col-sm-12 col-md-4 pb-0 pb-md-2">
      <div class="d-flex align-center">
        <v-avatar size="60">
          <img
            :src="addResourceImage(conversation.receiver_image)"
            class="border border-4"
            @error="imageFallback($event)"
          />
        </v-avatar>
        <div class="ml-3 text-primary">
          <h5>{{ conversation.receiver_name }}</h5>
          <div>{{ conversation.latest_message_time }}</div>
          <div class="fs-12 text-promary c-pointer" style="display: flex" v-if="remarkSwitch === 'on'">
            <div style="width: 100px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">{{ conversation.customer_to_seller_remark || '--' }}</div>
            <span @click="showUpdateRemark" style="text-decoration: underline; margin-left: 6px">{{ $t('remark') }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-md-8">
      <div class="d-flex align-center">
        <div>
          <h4>
            <router-link
              :to="`/user/product-query/` + conversation.slug"
              class="text-dark text-primary"
              style="color: #FF9595 !important"
            >
              {{ conversation.title }}
              <span
                v-if="conversation.sender_viewed == 0"
                class="custom-new-badge text-primary"
                
              >
                ({{ $t('new') }})
              </span>
            </router-link>
          </h4>
          <div v-if="conversation.latest_message.type">
            <img :src="addResourceImage(conversation.latest_message.message)" @error="imageFallback($event)" style="max-height: 50px;" />
          </div>
          <div v-else style="word-break: break-all; opacity: 0.7" class="text-primary">{{ conversation.latest_message.message }}</div>
        </div>
      </div>
    </div>
    <v-dialog v-model="isVisible" max-width="600px" @click:outside="closeDialog">
      <div class="white pa-5 rounded">
        <div class="mb-3">
          <div class="mb-1 fs-13 fw-500">{{ $t('remark') }}</div>
          <v-text-field
            :placeholder="$t('remark')"
            type="text"
            v-model="remark"
            hide-details="auto"
            required
            outlined
          ></v-text-field>
        </div>

        <div class="text-right mt-4">
          <v-btn text @click="closeDialog">{{ $t('cancel') }}</v-btn>
          <v-btn
            style="margin-left: 10px;"
            elevation="0"
            type="submit"
            color="primary"
            @click="updateRemark"
            :loading="adding"
            :disabled="adding"
          >
            {{ $t('update') }}
          </v-btn>
        </div>
      </div>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: {
    conversation: {
      type: Object,
      default: () => {}
    },
    remarkSwitch: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      adding: false,
      isVisible: false,
      remark: ''
    }
  },

  methods: {
    async updateRemark() {
      try {
        this.adding = true
        const res = await this.call_api('post', 'user/customer/updateinfo', { seller_id: this.conversation.receiver_id, remark: this.remark })

        this.adding = false

        if (res.data.success) {
          this.snack({ message: res.data.message })
          this.closeDialog()
          this.$emit('refresh')
        } else {
          this.snack({
          message: this.$i18n.t('set_success'),
          color: 'red'
        })
        }

      } catch(err) {
        this.adding = false
        console.log(err)
      }

    },
    showUpdateRemark() {
      this.remark = this.conversation.customer_remark ? this.conversation.customer_remark : ''
      this.isVisible = true
    },
    closeDialog() {
      this.isVisible = false
    }
  }
}
</script>
